import { colors } from "./colors"

export const theme = {
  colors,
  font: {
    regular: 400,
    bold: 700,
  },
  media: {
    tablet: "@media (min-width: 728px)",
    desktop: "@media (min-width: 1024px)",
  },
}
