import styled from "styled-components"

export const StyledLogo = styled.div`
  width: 180px;
  height: auto;
  position: relative;
  top: 2px;

  ${({ theme }) => theme.media.tablet} {
    width: 240px;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 250px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`
