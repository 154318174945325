import React from "react"
import { Link } from "gatsby"
import Flag from "react-world-flags"

import { StyledNavRow } from "./NavRow.styled"

const NavRow = () => {
  return (
    <StyledNavRow>
      <ul>
        <li>
          <Link to="/" activeClassName="active">
            <p>Home</p>
          </Link>
        </li>
        <li>
          <Link to="/aktualneoferty" activeClassName="active">
            <p>Zrealizowane projekty</p>
          </Link>
        </li>
        <li>
          <Link to="/onas" activeClassName="active">
            <p>O nas</p>
          </Link>
        </li>
        {/* <li>
          <Link to="/kariera" activeClassName="active">
            <p>Kariera</p>
          </Link>
        </li> */}
        <li>
          <Link to="/contact" activeClassName="active">
            <p>Kontakt</p>
          </Link>
        </li>
        {/* <li>
          <Link to="/home" activeClassName="active">
            <p>Blog</p>
          </Link>
        </li> */}
        <li className="flags">
          <Flag
            style={{
              border: "2px solid black",
              height: "20px",
              marginLeft: "16px",
            }}
            code={"PL"}
          />
          <Flag
            style={{
              border: "2px solid black",
              height: "20px",
              marginLeft: "6px",
            }}
            code={"GB"}
          />
          <Flag
            style={{
              border: "2px solid black",
              height: "20px",
              marginLeft: "6px",
            }}
            code={"DE"}
          />
        </li>
      </ul>
    </StyledNavRow>
  )
}

export default NavRow
