import React from "react"
import { Link } from "gatsby"
import Flag from "react-world-flags"

import { StyledNavColumn } from "./NavColumn.styled"

const NavColumn = ({ setOpen }) => {
  return (
    <StyledNavColumn>
      <ul>
        <li>
          <Link to="/" onClick={() => setOpen()} activeClassName="active">
            <p style={{ marginTop: "0" }}>Home</p>
          </Link>
        </li>
        <li>
          <Link
            to="/aktualneoferty"
            activeClassName="active"
            onClick={() => setOpen()}
          >
            <p>Zrealizowane projekty</p>
          </Link>
        </li>
        <li>
          <Link to="/onas" activeClassName="active" onClick={() => setOpen()}>
            <p>O nas</p>
          </Link>
        </li>
        {/* <li>
          <Link
            to="/kariera"
            activeClassName="active"
            onClick={() => setOpen()}
          >
            <p>Kariera</p>
          </Link>
        </li> */}
        <li>
          <Link
            to="/contact"
            activeClassName="active"
            onClick={() => setOpen()}
          >
            <p>Kontakt</p>
          </Link>
        </li>
        {/* <li>
          <Link
            to="/home"
            activeClassName="active"
            onClick={() => setOpen()}
          >
            <p>Blog</p>
          </Link>
        </li> */}
        <li style={{ display: "flex", justifyContent: "space-around" }}>
          <Flag
            style={{ border: "2px solid black", height: "30px" }}
            code={"PL"}
          />
          <Flag
            style={{ border: "2px solid black", height: "30px" }}
            code={"GB"}
          />
          <Flag
            style={{ border: "2px solid black", height: "30px" }}
            code={"DE"}
          />
        </li>
      </ul>
    </StyledNavColumn>
  )
}

export default NavColumn
