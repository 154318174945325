import React, { useEffect, useState } from "react"

import { StyledHeader } from "./Header.styled"

import { RemoveScroll } from "react-remove-scroll"

import { Link } from "gatsby"

import Burger from "../Burger/Burger"
import NavRow from "../NavRow/NavRow"
import Logo from "../Logo/Logo"

const Header = ({ open, setOpen }) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0)

  useEffect(() => {
    setScrollbarWidth(window.innerWidth - document.documentElement.clientWidth)
  }, [])

  return (
    <>
      <StyledHeader
        className={`${RemoveScroll.classNames.fullWidth}`}
        scrollbarWidth={scrollbarWidth}
      >
        <Link to="/">
          <Logo />
        </Link>

        <NavRow />
        <Burger open={open} setOpen={setOpen} />
      </StyledHeader>
    </>
  )
}

export default Header
