import React, { useState, useRef } from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"

import "../../assets/fonts/fonts.css"
import { theme } from "../../utils/theme"
import { useOnClickOutside } from "../../utils/hooks"

import Header from "../Header/Header"
import SideDrawer from "../SideDrawer/SideDrawer"
import Backdrop from "../Backdrop/Backdrop"
import Footer from "../Footer/Footer"

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto';
    background-color: ${({ theme }) => theme.colors.white}
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  p {
    margin: 0;
    line-height: 1.5;
  }
`

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false)
  const node = useRef()
  const hello = "hello"
  useOnClickOutside(node, () => setOpen(false))
  return (
    <ThemeProvider theme={theme} hello={hello}>
      <>
        <GlobalStyle />
        <div ref={node}>
          <Header open={open} setOpen={setOpen} />
          <SideDrawer open={open} setOpen={setOpen} />
        </div>
        <Backdrop open={open} />
        <div>{children}</div>
        <Footer />
      </>
    </ThemeProvider>
  )
}

export default Layout
