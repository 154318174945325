import styled from "styled-components"

export const StyledSideDrawer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) =>
    open ? "translateY(0)" : "translateY(calc(-100% + 59px))"};

  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`
