import styled from "styled-components"

export const StyledFooter = styled.footer`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  div {
    div {
      display: flex;

      svg {
        filter: invert(100%) brightness(104%);
      }

      h4 {
        margin: 0 0 24px 8px;
      }
    }
    div:last-child {
      h4 {
        margin: 0 0 0 8px;
      }
    }
  }
`
