import styled from "styled-components"

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 9px;
  z-index: 10;

  ${({ theme }) => theme.media.tablet} {
    display: none;
  }

  &:focus {
    outline: none;
  }

  div {
    width: 30px;
    height: 3px;
    background: ${({ theme }) => theme.colors.white};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")}
        ${({ open }) => (open ? "translate(3px, -3px)" : "translate(0, 0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")}
        ${({ open }) => (open ? "translate(3px, 3px)" : "translate(0, 0)")};
    }
  }
`
