import styled from "styled-components"

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100vw - ${({ scrollbarWidth }) => scrollbarWidth}px);
  height: 60px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 10;

  ${({ theme }) => theme.media.tablet} {
    padding: 0 30px;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 0 60px;
  }
`
