import styled from "styled-components"

export const StyledNavColumn = styled.nav`
  margin: 60px 0 0 0;
  ::before {
    content: "";
    height: 1px;
    width: 48px;
    background: ${({ theme }) => theme.colors.primary};
    display: inline-block;
    position: absolute;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
  }

  ::after {
    content: "";
    height: 1px;
    width: 48px;
    background: ${({ theme }) => theme.colors.primary};
    display: inline-block;
    position: absolute;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
  }
  ul {
    list-style-type: none;
    padding: 66px 0;
    margin: 0;

    li {
      a {
        text-decoration: none;
        text-align: center;

        p {
          text-transform: uppercase;
          font-size: 2.25rem;
          color: ${({ theme }) => theme.colors.black};
          margin: 20px 0;
        }
      }
      .active {
        p {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`
