import styled from "styled-components"

export const StyledNavRow = styled.nav`
  display: none;

  ul {
    list-style-type: none;
    padding: 0;
    marign: 0;
    display: flex;

    a {
      text-decoration: none;

      p {
        font-size: 1rem;
        padding-left: 20px;
        margin: 0;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.black};

        ${({ theme }) => theme.media.tablet} {
          padding-left: 10px;
        }

        ${({ theme }) => theme.media.desktop} {
          padding-left: 30px;
        }
      }
    }
    .active {
      p {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .flags {
    display:  none;
  }

  ${({ theme }) => theme.media.tablet} {
    display: inline-block;
  }

  ${({ theme }) => theme.media.desktop} {
    ul {
      .flags {
        display:  flex;
      }
    }
`
